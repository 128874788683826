import React, { createContext, useEffect, useState } from "react";
import { listCareAdvice } from "../client";

const CareAdviceContext = createContext();

const CareAdviceProvider = ({ children }) => {
  const [careAdvice, setCareAdvice] = useState([]);
  useEffect(() => {
    updateCareAdvice();
  }, []);

  const updateCareAdvice = async () => {
    const careAdvice = await listCareAdvice();
    setCareAdvice(careAdvice);
  };

  return (
    <CareAdviceContext.Provider
      value={{
        careAdvice,
        updateCareAdvice,
      }}
    >
      {children}
    </CareAdviceContext.Provider>
  );
};

export { CareAdviceProvider, CareAdviceContext };
