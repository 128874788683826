import React, { createContext, useState } from "react";
import { fetchCareReceiver } from "../client";

const CareReceiverContext = createContext();

const CareReceiverProvider = ({ children }) => {
  const [careReceiver, setCareReceiver] = useState({});

  const selectCareReceiver = async (id) => {
    const careReceiver = await fetchCareReceiver(id);
    setCareReceiver(careReceiver);
  };

  return (
    <CareReceiverContext.Provider
      value={{
        careReceiver,
        fetchCareReceiver: selectCareReceiver,
      }}
    >
      {children}
    </CareReceiverContext.Provider>
  );
};

export { CareReceiverProvider, CareReceiverContext };
