import React, { createContext, useEffect, useState } from "react";
import { selectUserByEmail } from "../client";

const UserContext = createContext();

const UserProvider = ({ children, user }) => {
  const [userRecord, setUser] = useState({
    initializing: true,
    loading: false,
    data: {},
    error: null,
  });

  useEffect(() => {
    if (user && user.emails && user.emails[0].email) {
      setUser({
        initializing: true,
        loading: true,
        data: {},
        error: null,
      });
      fetchUser(user.emails[0].email);
    }
  }, [user && user.emails && user.emails[0].email]);

  const fetchUser = async (email) => {
    const userRecord = await selectUserByEmail(email);
    setUser({
      initializing: false,
      loading: false,
      data: userRecord,
      error: null,
    });
  };

  return (
    <UserContext.Provider
      value={{
        user: userRecord,
        fetchUser,
      }}
    >
      {userRecord.loading ? null : children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
