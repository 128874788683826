import { CarePlanProvider } from "../contexts/care-plan-context";
import { VendorProvider } from "./vendor-context";
import { CareReceiverProvider } from "./care-receiver-context";
import { CareAdviceProvider } from "./care-advice-context";
import { CareTypeProvider } from "./care-type-context";
import { UserProvider } from "./user-context";

const Contexts = ({ children, user }) => (
  <UserProvider user={user}>
    <CarePlanProvider>
      <VendorProvider>
        <CareTypeProvider>
          <CareAdviceProvider>
            <CareReceiverProvider>{children}</CareReceiverProvider>
          </CareAdviceProvider>
        </CareTypeProvider>
      </VendorProvider>
    </CarePlanProvider>
  </UserProvider>
);

export default Contexts;
