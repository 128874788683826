import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { StytchProvider } from "@stytch/react";
import { StytchHeadlessClient } from "@stytch/vanilla-js/headless";

import AuthenticatedApp from "./authenticated-app";

const stytch = new StytchHeadlessClient(
  process.env.REACT_APP_STYTCH_PUBLIC_TOKEN
);
const App = () => {
  return (
    <StytchProvider stytch={stytch}>
      <Router>
        <AuthenticatedApp />
      </Router>
    </StytchProvider>
  );
};

export default App;
