import React, { createContext, useState, useContext, useEffect } from "react";
import { fetchCarePlan } from "../client";
import { UserContext } from "./user-context";

const CarePlanContext = createContext();

const CarePlanProvider = ({ children }) => {
  const [carePlan, setCarePlan] = useState({
    loading: true,
    data: {},
    error: null,
  });
  const {
    user: { initializing, loading, data: user },
  } = useContext(UserContext);
  const carePlanId = user?.fields && user?.fields["Care Plan"];
  useEffect(() => {
    if (!loading && !initializing) selectCarePlan(carePlanId);
  }, [carePlanId, loading, initializing]);

  const selectCarePlan = async (id) => {
    setCarePlan({ loading: true, error: null, data: carePlan.data });
    const newCarePlan = await fetchCarePlan(id);
    setCarePlan({ loading: false, error: null, data: newCarePlan });
  };

  return (
    <CarePlanContext.Provider value={{ carePlan, fetchCarePlan }}>
      {children}
    </CarePlanContext.Provider>
  );
};

export { CarePlanProvider, CarePlanContext };
