import React, { lazy, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStytch, useStytchSession, useStytchUser } from "@stytch/react";
import { isStandalone } from "../helpers";

import ContextProviders from "../contexts";

const Header = lazy(() => import("./header"));
const Main = lazy(() => import("./main"));
const Footer = lazy(() => import("./footer"));

const AuthenticatedApp = () => {
  const stytchClient = useStytch();
  const { session } = useStytchSession();
  const { user } = useStytchUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (session && user) {
      navigate("/");
    } else {
      let token;

      if (isStandalone()) {
        const decodedPathname = decodeURIComponent(window.location.pathname);
        const url = new URL(
          decodedPathname.slice(1) || "/",
          window.location.href
        );
        token = url.searchParams.get("token");
      } else token = new URLSearchParams(window.location.search).get("token");
      if (token === null) navigate("/login");
      else {
        stytchClient.magicLinks.authenticate(token, {
          session_duration_minutes: 60,
        });
      }
    }
  }, [stytchClient, session]);

  return (
    <ContextProviders user={user}>
      <div className="flex flex-col divide-y-2">
        <Header />
        <Main />
        <Footer />
      </div>
    </ContextProviders>
  );
};

export default AuthenticatedApp;
