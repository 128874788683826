export const selectUserByEmail = async (email) => {
  const response = await fetch(`${window.baseUrl}/user?email=${email}`);
  return await response.json();
};

export const listCareAdvice = async () => {
  const response = await fetch(`${window.baseUrl}/care-advice`);
  return await response.json();
};

export const listCareTypes = async () => {
  const response = await fetch(`${window.baseUrl}/care-type`);
  return await response.json();
};

export const fetchCarePlan = async (id) => {
  const response = await fetch(`${window.baseUrl}/care-plan/${id}`);
  return await response.json();
};

export const fetchVendor = async (id) => {
  const response = await fetch(`${window.baseUrl}/vendor/${id}`);
  return await response.json();
};

export const fetchCareReceiver = async (id) => {
  const response = await fetch(`${window.baseUrl}/care-receiver/${id}`);
  return await response.json();
};
