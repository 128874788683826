import React, { createContext, useEffect, useState, useContext } from "react";
import { listCareTypes } from "../client";
import { CarePlanContext } from "./care-plan-context";
import { VendorContext } from "./vendor-context";

const CareTypeContext = createContext();

const CareTypeProvider = ({ children }) => {
  const [availableCareTypes, setAvailableCareTypes] = useState({
    loading: true,
    data: [],
    error: null,
  });
  const [careTypes, setCareTypes] = useState({ loading: true, data: {} });

  const {
    carePlan: { loading: carePlanLoading },
  } = useContext(CarePlanContext);

  const {
    vendors: { loading: vendorsLoading, data: vendors },
  } = useContext(VendorContext);

  useEffect(() => {
    fetchAvailableCareTypes();
  }, []);

  useEffect(() => {
    if (!carePlanLoading && !vendorsLoading && !availableCareTypes.loading) {
      updateCareTypes();
    }
  }, [carePlanLoading, vendorsLoading, availableCareTypes.loading]);

  const fetchAvailableCareTypes = async () => {
    const careTypes = await listCareTypes();
    const careTypesDict = careTypes.reduce((acc, cur) => {
      acc[cur.id] = cur.fields;
      return acc;
    }, {});
    setAvailableCareTypes({ loading: false, data: careTypesDict, error: null });
  };

  const updateCareTypes = () => {
    const careTypes = vendors.reduce((acc, cur) => {
      const careTypeId = cur.fields["Care Type"];
      acc[careTypeId] = availableCareTypes.data[careTypeId];
      return acc;
    }, {});
    setCareTypes({ loading: false, data: careTypes, error: null });
  };

  return (
    <CareTypeContext.Provider
      value={{
        availableCareTypes,
        careTypes,
        updateCareTypes,
      }}
    >
      {children}
    </CareTypeContext.Provider>
  );
};

export { CareTypeProvider, CareTypeContext };
