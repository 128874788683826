import React, { createContext, useState, useEffect, useContext } from "react";
import { fetchVendor } from "../client";
import { CarePlanContext } from "./care-plan-context";

const VendorContext = createContext();

const VendorProvider = ({ children }) => {
  const [vendors, setVendors] = useState({
    loading: true,
    data: [],
    asDict: {},
    error: null,
  });
  const {
    carePlan: { loading, data: carePlan },
  } = useContext(CarePlanContext);

  useEffect(() => {
    if (!loading && carePlan.fields) fetchVendors();
  }, [loading, carePlan?.fields?.Vendor || null]);

  const fetchVendors = async () => {
    const ids = carePlan.fields.Vendor;
    if (ids) {
      const deferredFetchVendors = ids?.map((id) => fetchVendor(id));
      const vendors = await Promise.all(deferredFetchVendors);
      const asDict = vendors.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      setVendors({ loading: false, data: vendors, asDict, error: null });
    }
  };

  return (
    <VendorContext.Provider
      value={{
        vendors,
        fetchVendors,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};

export { VendorProvider, VendorContext };
